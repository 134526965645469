import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [5,2,4];

export const dictionary = {
		"/(marketing-site)/form/[type=formType]": [9,[5]],
		"/(handbook)/handbook": [~6,[2],[3]],
		"/(handbook)/handbook/[...path]": [~7,[2,4],[3]],
		"/(proposals)/proposal/[slug]": [~11],
		"/(proposals)/proposal/[slug]/accept": [~12],
		"/(marketing-site)/segg/[segg]": [~10,[5]],
		"/(marketing-site)/[...path]": [~8,[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';